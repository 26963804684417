import BookingForm from "@/components/BookingForm";
import CourseBadges from "@/components/CourseBadges";
import CoursePage from "@/components/CoursePage";
import Para from "@/components/Para";
import SpacePic from "@/components/SpacePic";
import { Box } from "@chakra-ui/react";
import { PageProps } from "gatsby";
import React, { ReactElement } from "react";

export function ReactWithTypeScriptAbstract(): ReactElement {
  return (
    <Box>
      <CourseBadges days={3} />
      <Para>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Distinctio, eum
        eius ipsa sit vel possimus autem dolore nemo quasi at doloremque
        reiciendis, ipsam animi excepturi quisquam ut? Tempora, odit mollitia!
      </Para>
    </Box>
  );
}

function ReactWithTypeScriptOutline(): ReactElement {
  return <p>Coming soon...</p>;
}

export default function ReactWithTypeScriptPage(
  _props: PageProps
): ReactElement {
  return (
    <CoursePage
      title="React with TypeScript"
      category="typescript"
      accent="orange"
      abstract={<ReactWithTypeScriptAbstract />}
      bookingForm={<BookingForm /* defaultCourse="react-with-typescript" */ />}
      picture={<SpacePic name="astronaut" maxW="96" mx="auto" />}
      outline={<ReactWithTypeScriptOutline />}
    />
  );
}
